import * as React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  DateField,
  TextField,
  ImageField,
  TextInput,
  BooleanInput,
  DateTimeInput,
  useRecordContext,
  SimpleForm,
  SimpleShowLayout,
} from 'react-admin';

const adFilters = [
  <TextInput label="Title Search" source="title" alwaysOn resettable />
];

export const AdList = () => (
  <List filters={ adFilters }>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="ad_type" />
      <TextField source="asp" />
      <TextField source="title" />
      <TextField source="hidden" />
      <TextField source="hidden_reason" />
      <DateField source="created_at" showTime locales="ja-JP" />
      <DateField source="updated_at" showTime locales="ja-JP" />
    </Datagrid>
  </List>
);

const AdTitle = () => {
  const record = useRecordContext();
  return <span>Ad: { record ? `${ record.title }` : '' }</span>;
};

export const AdEdit = () => (
  <Edit title={ <AdTitle /> }>
    <SimpleForm>
      <TextInput source="id" disabled />
      <TextInput source="ad_type" helperText="Accept values: article, article_[#], article_odd, article_even" />
      <TextInput source="asp" helperText="Accept values: dti, dmm" />
      <TextInput source="title" fullWidth />
      <TextInput source="thumb_src" fullWidth />
      <SimpleShowLayout>
        <ImageField source="thumb_src" className="thumb-in-edit" />
      </SimpleShowLayout>
      <TextInput source="ad_uri" fullWidth />
      <BooleanInput source="hidden" />
      <TextInput source="hidden_reason" fullWidth />
      <DateTimeInput source="created_at" disabled />
      <DateTimeInput source="updated_at" disabled />
    </SimpleForm>
  </Edit>
);

export const AdCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="ad_type" helperText="Accept values: article, article_[#], article_odd, article_even" />
      <TextInput source="asp" helperText="Accept values: dti, dmm" />
      <TextInput source="title" fullWidth />
      <TextInput source="thumb_src" fullWidth />
      <TextInput source="ad_uri" fullWidth />
      <BooleanInput source="hidden" />
      <TextInput source="hidden_reason" fullWidth />
    </SimpleForm>
  </Create>
);
