import '../styles/Detail.scss';
import React from 'react';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import BasicLayout from './basicLayout';
import Article from './article';
import ApiClient from '../services/api_client';
import Util from '../services/util';
import { withRouter } from './with_router';

class Detail extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      videoId: "",
      videoTitle: "",
      width: "",
      height: "",
      embedUrl: "",
      playCount: "",
      postedAt: "",
      dmmTitle: "",
      dmmDescription: "",
      dmmThumbUrl: "",
      dmmCid: "",
      dmmLitevideoPresent: false,
      dmmLitevideoSrc: "",
      description: "",
      tags: [],
      relatedVideos: []
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.params !== prevProps.params) {
      this.loadData();
    }
  }

  componentDidMount = () => {
    this.loadData();
  }

  loadData = async () => {

    this.setState({
      videoId: "",
      videoTitle: "",
      width: "",
      height: "",
      embedUrl: "",
      playCount: "",
      postedAt: "",
      dmmTitle: "",
      dmmDescription: "",
      dmmThumbUrl: "",
      dmmCid: "",
      dmmLitevideoPresent: false,
      dmmLitevideoSrc: "",
      description: "",
      tags: [],
      relatedVideos: []
    });

    const { videoId } = this.props.params;

    const apiPath = `/api/videos/${ videoId }`;
    const query = { include_related: true };
    const req = await ApiClient.getRequestTo(apiPath, query);

    fetch(req).then((res) => {
      if (!res.ok) {
        throw Error(`[GET ${ apiPath }] ${ res.status } ${ res.statusText }`);
      }

      return res.json();
    }).then((json) => {
      const { video, related_videos } = json.data;

      this.setState({
        videoId: video.id,
        videoTitle: video.title,
        width: video.width,
        height: video.height,
        embedUrl: video.embed_src,
        playCount: video.play_count,
        postedAt: video.posted_at,
        dmmTitle: video.dmm_title,
        dmmDescription: video.dmm_description,
        dmmThumbUrl: video.my_dmm_thumb_src,
        dmmCid: video.dmm_cid,
        dmmLitevideoPresent: video.dmm_litevideo_present,
        dmmLitevideoSrc: video.dmm_litevideo_src,
        description: video.description,
        tags: video.tags,
        relatedVideos: related_videos
      });

      document.title = `無料AVすとりーむ | ${ video.title }`;
      const cmt = document.createComment(`erKokDMM:${ video.dmm_cid }`);
      document.head.insertBefore(cmt, document.head.firstChild);
      const el = document.getElementById("erKokOrigin");
      while( el.firstChild ){
        el.removeChild( el.firstChild );
      }
      /* eslint-disable-next-line */
      erKokOrigin();
    }).catch((err) => {
      console.error(err);
    }).finally(() => {
    });
  }

  render() {
    const { videoTitle, width, height, embedUrl, playCount, postedAt, dmmTitle, dmmCid, dmmLitevideoPresent, dmmLitevideoSrc, description, tags, relatedVideos } = this.state;

    const postedDate = new Date(postedAt);

    let tagList = tags.map(tag => {
      return (
        <li key={ tag.name }><a href={ `/tags/${ tag.name }` }>{ tag.name }</a></li>
      );
    });

    let embedUrlWithAff = embedUrl;
    if (embedUrl && embedUrl.match(/https:\/\/(txxx.com|vjav.com|senzuri.tube)\/.*/)) {
      embedUrlWithAff += `?source=${ process.env.REACT_APP_TUBE_CORP_EMBED_SOURCE_ID }`;
    }

    const dmmLitevideoAfLink = `https://www.dmm.co.jp/litevideo/-/part/=/affi_id=${ process.env.REACT_APP_DMM_AF_ID }/cid=${ dmmCid }/size=1280_720/`;

    let articleList = relatedVideos.map(video => {
      return (
        <Col md={ 6 } className="article-item-col" key={ video.id }>
          <Article
            videoTitle={ video.title }
            videoDetailUrl={ `/detail/${ video.id }` }
            thumbUrl={ video.my_thumb_src }
            lengthMin={ video.length_min }
            lengthSec={ video.length_sec }
            playCount={ video.play_count }
            postedAt={ video.posted_at }
          />
        </Col>
      );
    });

    return (
      <BasicLayout>
        <Container fluid className="detail-container">
          <Row className="player-wrap">
            <Col>
              <div className="player">
                <iframe title={ videoTitle } width={ width } height={ height } src={ embedUrlWithAff } frameBorder="0" scrolling="no" allowFullScreen></iframe>
              </div>
            </Col>
          </Row>
          <Row className="article-content-wrap">
            <Col md={ 8 }>
              <div className="article-content">
                <h3 className="article-title">{ videoTitle }</h3>
                <div className="article-data">
                  <ul className="article-tags">
                    { tagList }
                  </ul>
                  <div className="article-info-wrap">
                    <ul className="article-info article-info-first">
                      <li className="article-date">{ postedDate.toLocaleDateString() }に公開</li>
                      <li>{ Util.thousandSeparate(playCount) }回再生 </li>
                    </ul>
                  </div>
                </div>
              </div>
              { description && 
                <div className="rev-content">
                  <p className="content-header">作品レビュー</p>
                  <div className="rev-desc">
                    { description }
                  </div>
                </div>
              }
              <div id="erKokOrigin" className="erKokOrigin dmm-content"></div>
              { dmmLitevideoPresent &&
                <div className="dmm-content">
                  <p className="content-header"> DMMサンプル動画</p>
                  <div className="dmm-litevideo-wrap">
                    <iframe title={ dmmTitle } src={ dmmLitevideoSrc || dmmLitevideoAfLink } scrolling="no" frameBorder="0" allowFullScreen></iframe>
                  </div>
                </div>
              }
            </Col>
            <Col md={ 4 }>
              <div className="related-items">
                <Row>
                  <h2 className="related-items-title">関連動画</h2>
                </Row>
                <Row>
                  { articleList }
                </Row>
                <Row>
                  <div id="eroterest_partsv2" className="eroterest_partsv2"></div>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
        <Helmet>
          <script src="//bpm.eroterest.net/v2/?w=&e=&n=8&rn=2&spn=4&sprn=1&fc=&ts=&tp=&tbg=&tsz=&tr=&tc=&tmc=&tu=1&is=1&mts=&ds=&dsz=&dr=&dc=&df=&ibg=&ibm=&ids=&ib=&ibc=&ir=&sf="></script>
        </Helmet>
      </BasicLayout>
    )
  }
}

export default withRouter(Detail);
