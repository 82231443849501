import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Article.scss';
import Util from '../services/util';

class Article extends React.Component {
  render() {
    const { lengthMin, lengthSec } = this.props;
    let lengthStr;
    if (lengthMin !== null && lengthSec !== null) {
      const lengthMinPad = lengthMin > 9 ? lengthMin : ('0' + lengthMin).slice(-2);
      const lengthSecPad = ('0' + lengthSec).slice(-2);
      lengthStr = lengthMinPad + ":" + lengthSecPad;
    }

    const postedAt = new Date(this.props.postedAt);
    const elapsedHour = Math.ceil((Date.now() - postedAt) / (60*60*1000));

    let elapsed;
    if (elapsedHour < 24) {
      elapsed = `${ elapsedHour }時間前`
    } else {
      elapsed = `${ Math.floor(elapsedHour / 24) }日前`
    }

    return (
      <div className="article-item">
        <div className="article-item-thumb">
          <Link to={ this.props.videoDetailUrl } title={ this.props.videoTitle }>
            <img loading="lazy" src={ this.props.thumbUrl } alt={ this.props.videoTitle } className="article-item-image" />
            <span className="video-data playtime">
              { lengthStr }
            </span>
            <span className="videoplay" title="再生">
              <svg className="videoplay-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M8 5v14l11-7z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
            </span>
          </Link>
        </div>
        <div className="article-item-content">
          <Link to={ this.props.videoDetailUrl } title={ this.props.videoTitle }>
            <h3 className="article-item-title">
              { this.props.videoTitle }
            </h3>
            <div className="article-item-data">
              <span className="article-item-text">{ Util.thousandSeparate(this.props.playCount) }回再生</span>
              <span className="article-item-text">{ elapsed }</span>
            </div>
          </Link>
        </div>
      </div>
    )
  }
}

export default Article;
