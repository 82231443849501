import React from 'react';
import {
  Container,
  Row,
  Col,
  Pagination
} from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import BasicLayout from './basicLayout';
import Article from './article';
import AdArticle from './ad_article';
import ApiClient from '../services/api_client';
import Util from '../services/util';
import { withRouter } from './with_router';

class Search extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      videos: [],
      totalCount: "",
      headTitle: "",
      ads: [],
    }
    this.PER_PAGE = 20;
  }

  componentDidUpdate(prevProps) {
    if (this.props.params !== prevProps.params) {
      this.initData();
    }
  }

  componentDidMount = async () => {
    this.initData();
  }

  initData = async () => {
    if (this.state.loading) { return };

    this.setState({
      loading: false,
      videos: [],
      totalCount: "",
      headTitle: "",
      ads: [],
    }, () => this.loadData());
  }

  loadData = async () => {
    if (this.state.loading) { return };

    this.setState({ loading: true });

    const { searchBy } = this.props;
    const page = this.props.params.page || 1;

    let query = {
      limit: this.PER_PAGE,
      offset: (page - 1) * this.PER_PAGE,
    };

    let headTitle = searchBy ? "検索： " : "動画一覧";

    if (searchBy === "tags") {
      const { tags } = this.props.params;
      query.tags = tags;
      headTitle += tags.split(",").map(tag => {
        return `#${ tag }`
      }).join(" ")
    } else if (searchBy === "word") {
      const { word } = this.props.params;

      const firstWord = word.split(" ")[0]
      query.word = firstWord;
      headTitle += `${ firstWord }`
    }

    let adTypes = ["article",`article_${ page }`]
    adTypes.push(page % 2 === 0 ? "article_even" : "article_odd");
    query.ad_types = adTypes;
    query.ad_limit = 2;

    const apiPath = `/api/videos`
    const req = await ApiClient.getRequestTo(apiPath, query);

    fetch(req).then((res) => {
      if (!res.ok) {
        throw Error(`[GET ${ apiPath }] ${ res.status } ${ res.statusText }`);
      }

      return res.json();
    }).then((json) => {
      this.setState({
        videos: json.data.videos,
        totalCount: json.data.total_count,
        headTitle: headTitle,
        ads: json.data.ads,
      });

      document.title = `無料AVすとりーむ | ${ headTitle }`;
    }).catch((err) => {
      console.error(err);
    }).finally(() => {
      this.setState({
        loading: false
      });
    });
  }

  onPageClick = (e) => {
    e.preventDefault();
    const uri = new URL(e.target.href);
    this.props.navigate(uri.pathname);
  }

  render() {
    let articles = this.state.videos.map(video => {
      return (
        <Col md={ 3 } className="article-item-col" key={ video.id }>
          <Article
            videoTitle={ video.title }
            videoDetailUrl={ `/detail/${ video.id }` }
            thumbUrl={ video.my_thumb_src }
            lengthMin={ video.length_min }
            lengthSec={ video.length_sec }
            playCount={ video.play_count }
            postedAt={ video.posted_at }
          />
        </Col>
      );
    })

    let ads = this.state.ads.map(ad => {
      return (
        <Col md={ 3 } className="article-item-col" key={ ad.id }>
          <AdArticle
            asp={ ad.asp }
            title={ ad.title }
            adUri={ ad.ad_uri }
            thumbUrl={ ad.thumb_src }
          />
        </Col>
      );
    })
    ads.forEach((ad, i) => {
      const idx = 5*(i+1);
      if (articles.length > idx) {
        const rand = Math.floor(Math.random() * 2);
        articles.splice(idx-rand, 0, ad);
      }
    })

    let pages = [1,2,3,4,5];
    const lastPage = Math.ceil(this.state.totalCount / this.PER_PAGE);
    const currentPage = (this.props.params.page * 1) || 1;

    if (lastPage < 5) {
      pages = pages.filter(n => n <= lastPage);
    } else {

      if (currentPage > 3) {
        const diff = currentPage - pages[2];
        pages = pages.map(n => n + diff);
      }

      if (pages[4] > lastPage) {
        const diff = pages[4] - lastPage;
        pages = pages.map(n => n - diff);
      }

      if (pages[0] > 1) {
        if (pages[0] > 2) {
          pages.unshift("prev");
        }
        pages.unshift(1);
      }

      if (pages[pages.length-1] < lastPage) {
        if (pages[pages.length-1] < lastPage -1) {
          pages.push("next");
        }
        pages.push(lastPage);
      }
    }

    const locationPath = this.props.location.pathname;
    const locationMatch = locationPath.match(/(\/(tags|word)\/[^/]+).*/);
    const basePath = locationMatch ? locationMatch[1] : "";

    const pagination =
      <Pagination>
        { pages.map(n => {
          if (n === "prev") {
            const targetPage = pages[2] - 1;
            return (
              <Pagination.Ellipsis
                key={ targetPage }
                href={ `${ basePath }/page/${ targetPage }` }
              />
            )
          } else if (n === "next") {
            const targetPage = pages[pages.length - 3] + 1;
            return (
              <Pagination.Ellipsis
                key={ targetPage }
                href={ `${ basePath }/page/${ targetPage }` }
              />
            )
          } else {
            return (
              <Pagination.Item
                key={ n }
                active={ n === currentPage }
                href={ `${ basePath }/page/${ n }` }
                onClick={ this.onPageClick }
              >
                { n }
              </Pagination.Item>
            )
          }
        })}
      </Pagination>

    return (
      <BasicLayout>
        <Container fluid className="search-container">
          <Row className="justify-content-center">
            <Col xs="auto" className="d-block d-md-none">
              <iframe src={ `https://www.mmaaxx.com/genre/944400X/index300250.html?affid=${ process.env.REACT_APP_DTI_AF_ID }?genre=adult` } width="300" height="250" frameBorder="no" scrolling="no" title="バナー"></iframe>
            </Col>
          </Row>
          <Row className="title-wrap">
            <Col md={6}>
              <h2 className="title">{ this.state.headTitle }</h2>
            </Col>
            <Col md={6} className="text-end">
              <span className="post-count">
                { Util.thousandSeparate(this.state.totalCount) } 本の動画
              </span>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs="auto">
              { pagination }
            </Col>
          </Row>
          <Row>
            { articles }
          </Row>
          <Row className="justify-content-md-center mt-1 mb-1">
            <Col md="auto" className="">
              <div id="eroterest_partsv2" className="eroterest_partsv2"></div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs="auto">
              { pagination }
            </Col>
          </Row>
          <Row className="justify-content-md-center mt-1">
            <Col md="auto" className="d-none d-md-block">
              <iframe src={ `https://www.mmaaxx.com/genre/944400X/index700.html?affid=${ process.env.REACT_APP_DTI_AF_ID }?genre=adult` } width="700" height="200" frameBorder="no" scrolling="no" title="バナー"></iframe>
            </Col>
          </Row>
        </Container>
        <Helmet>
<script src="//bpm.eroterest.net/v2/?w=&e=&n=4&rn=4&spn=4&sprn=2&fc=&ts=&tp=&tbg=&tsz=&tr=&tc=&tmc=&tu=1&is=1&mts=&ds=&dsz=&dr=&dc=&df=&ibg=&ibm=&ids=&ib=&ibc=&ir=&sf="></script>
        </Helmet>
      </BasicLayout>
    )
  }
}

export default withRouter(Search);
