import React, { useState } from 'react';
import {
  List,
  Datagrid,
  Edit,
  DateField,
  TextField,
  BooleanField,
  ImageField,
  TextInput,
  BooleanInput,
  NumberInput,
  DateTimeInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  useRecordContext,
  required,
  TabbedForm,
  FormTab,
  SimpleShowLayout,
} from 'react-admin';
import { Link } from '@mui/material';

const videoFilters = [
  <TextInput label="Title Search" source="title" alwaysOn resettable />,
  <BooleanInput label="Hidden" source="hidden" />,
  <BooleanInput label="VR" source="is_vr" />
];

export const VideoList = () => {
  const [ showThumb, setShowThumb ] = useState(false);

  return (
    <List filters={ videoFilters }>
      <label>
        <input
          type="checkbox"
          checked={ showThumb }
          onChange={e => setShowThumb(e.currentTarget.checked)}
          className="m-2"
        />
        Show thumbnail
      </label>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="title" />
        <DateField source="posted_at" showTime locales="ja-JP" />
        <TextField source="dmm_cid" />
        <TextField source="original_site" />
        { showThumb && <IframeField source="embed_src" /> }
        <BooleanField source="hidden" />
        <TextField source="hidden_reason" />
        <BooleanField source="is_vr" />
        <DateField source="created_at" showTime locales="ja-JP" />
        <DateField source="updated_at" showTime locales="ja-JP" />
      </Datagrid>
    </List>
  )
};

const VideoTitle = () => {
  const record = useRecordContext();
  return <span>Video: { record ? `${ record.title }` : '' }</span>;
};

const IframeField = ({ source }) => {
  const record = useRecordContext();
  return <iframe src={ record && record[source] } title={ record && record.title } />;
};

const IframeLitevideoField = ({ source }) => {
  const record = useRecordContext();
  if (!record || !record['dmm_litevideo_present']) return;
  const src = (record[source]) || `https://www.dmm.co.jp/litevideo/-/part/=/cid=${ record['dmm_cid'] }`;
  return <iframe src={ src } title={ record && record.title } />;
};

const DetailLinkField = ({ source }) => {
  const record = useRecordContext();
  return (
    <Link href={ `/detail/${ record && record.id }` } target="_blank" >
      ID: { record.id }
    </Link>
  )
};

const DmmLinkField = ({ source }) => {
  const record = useRecordContext();
  const link = `https://www.dmm.co.jp/digital/videoa/-/detail/=/cid=${ record && record[source] }/`;
  return <a href={ link } target="_blank" rel="noreferrer">DMMページ</a>
};

export const VideoEdit = () => (
  <Edit title={ <VideoTitle /> }>
    <TabbedForm>
      <FormTab label="title">
        <DetailLinkField source="id" />
        <TextInput source="title" fullWidth validate={ required() } />
        <BooleanInput source="hidden" />
        <TextInput source="hidden_reason" fullWidth />
        <TextInput source="dmm_title" fullWidth />
        <TextInput source="dmm_description" fullWidth multiline />
        <DmmLinkField source="dmm_cid" />
        <DateTimeInput source="posted_at" validate={ required() } />
        <NumberInput source="play_count" validate={ required() } />
        <ReferenceArrayInput source="tag_ids" reference="tags" enableGetChoices={({ q }) => (q ? q.length >= 1 : false)} >
          <AutocompleteArrayInput
            shouldRenderSuggestions={value => value.length >= 1}
          />
        </ReferenceArrayInput>
      </FormTab>
      <FormTab label="images">
        <TextInput source="thumb_src" fullWidth validate={ required() } />
        <TextInput source="embed_src" fullWidth />
        <TextInput source="dmm_thumb_src" fullWidth />
        <TextInput source="dmm_litevideo_src" fullWidth />
        <BooleanInput source="dmm_litevideo_present" />
        <SimpleShowLayout>
          <ImageField source="thumb_src" className="thumb-in-edit" />
          <IframeField source="embed_src" />
          <ImageField source="dmm_thumb_src" className="thumb-in-edit" />
          <IframeLitevideoField source="dmm_litevideo_src" />
        </SimpleShowLayout>
        <DmmLinkField source="dmm_cid" />
      </FormTab>
      <FormTab label="misc">
        <TextInput source="original_site" disabled />
        <TextInput source="original_video_id" label="Original video id (Site path)" disabled />
        <TextInput source="dmm_cid" disabled />
        <BooleanInput source="is_vr" />
        <NumberInput source="length_min" />
        <NumberInput source="length_sec" />
        <NumberInput source="width" />
        <NumberInput source="height" />
        <TextInput source="description" fullWidth multiline />
        <TextInput source="my_thumb_src" disabled fullWidth />
        <TextInput source="my_dmm_thumb_src" disabled fullWidth />
        <DateTimeInput source="created_at" disabled />
        <DateTimeInput source="updated_at" disabled />
      </FormTab>
    </TabbedForm>
  </Edit>
);
