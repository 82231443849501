import React from 'react';
import {
  Container,
  Navbar,
} from 'react-bootstrap';

class Navigation extends React.Component {
  handleToggleSidebar = () => {
    this.props.handleToggleSidebar(true);
  }

  render() {
    return (
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="/">無料<span className="text-danger">AV</span>すとりーむ</Navbar.Brand>
          <Navbar.Toggle onClick={ this.handleToggleSidebar } />
        </Container>
      </Navbar>
    )
  }
}

export default Navigation;
