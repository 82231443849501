const authProvider = {
  login: ({ username, password }) => {
    localStorage.setItem('username', username);
    localStorage.setItem('password', password);
    return Promise.resolve();
  },
  logout: () => {
    localStorage.removeItem('username');
    localStorage.removeItem('password');
    return Promise.resolve();
  },
  checkAuth: () =>
    localStorage.getItem('password') ? Promise.resolve() : Promise.reject()
  ,
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('username');
      localStorage.removeItem('password');
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  getIdentity: () =>
    Promise.resolve({
      id: 0,
      fullName: localStorage.getItem('username'),
    })
  ,
  getPermissions: () => Promise.resolve(''),
};

export default authProvider;
