import './styles/App.scss';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { useLayoutEffect } from 'react';
import Search from './components/search';
import Detail from './components/detail';
import AdminRoute from './components/admin/admin_route';

const ScrollToTop = ({children}) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
}

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route exact path="/" element={ <Search /> } />
          <Route exact path="/page/:page" element={ <Search /> } />
          <Route exact path="/detail/:videoId" element={ <Detail /> } />
          <Route exact path="/tags/:tags" element={ <Search searchBy="tags" /> } />
          <Route exact path="/tags/:tags/page/:page" element={ <Search searchBy="tags" /> } />
          <Route exact path="/word/:word" element={ <Search searchBy="word" /> } />
          <Route exact path="/word/:word/page/:page" element={ <Search searchBy="word" /> } />
          <Route exact path="/admin/*" element={ <AdminRoute /> } />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
