import * as React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  DateField,
  TextField,
  TextInput,
  SelectInput,
  DateTimeInput,
  useRecordContext,
  SimpleForm,
} from 'react-admin';

const tagFilters = [
  <TextInput label="Name Search" source="name" alwaysOn resettable />
];

export const TagList = () => (
  <List filters={ tagFilters }>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="alt_names" />
      <TextField source="tag_type" />
      <TextField source="video_count" />
      <DateField source="created_at" showTime locales="ja-JP" />
      <DateField source="updated_at" showTime locales="ja-JP" />
    </Datagrid>
  </List>
);

const TagTitle = () => {
  const record = useRecordContext();
  return <span>Tag: { record ? `${ record.name }` : '' }</span>;
};

export const TagEdit = () => (
  <Edit title={ <TagTitle /> }>
    <SimpleForm>
      <TextInput source="id" disabled />
      <TextInput source="name" />
      <TextInput source="alt_names" fullWidth helperText="Use brackets for each word. ex: '[abc][ABC]'" />
      <SelectInput source="tag_type" choices={[
        { id: 'actress', name: 'actress' },
        { id: 'series', name: 'series' },
      ]} />
      <TextInput source="video_count" disabled />
      <DateTimeInput source="created_at" disabled />
      <DateTimeInput source="updated_at" disabled />
    </SimpleForm>
  </Edit>
);

export const TagCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="alt_names" fullWidth helperText="Use brackets for each word. ex: '[abc][ABC]'" />
      <SelectInput source="tag_type" choices={[
        { id: 'actress', name: 'actress' },
        { id: 'series', name: 'series' },
      ]} />
    </SimpleForm>
  </Create>
);
