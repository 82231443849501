import React from 'react';
import '../styles/Article.scss';

class AdArticle extends React.Component {
  render() {
    const afId = process.env[`REACT_APP_${ this.props.asp.toUpperCase() }_AF_ID`];
    const afUri = this.props.adUri.replace(/\[AF_ID\]/g, afId);

    return (
      <div className="article-item">
        <div className="article-item-thumb">
          { /* eslint-disable-next-line */ }
          <a href={ afUri } title={ this.props.title } target="_blank">
            <img loading="lazy" src={ this.props.thumbUrl } alt={ this.props.title } className="article-item-ad-image" />
            <span className="videoplay" title="再生">
              <svg className="videoplay-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M8 5v14l11-7z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
            </span>
          </a>
        </div>
        <div className="article-item-content">
          { /* eslint-disable-next-line */ }
          <a href={ afUri } title={ this.props.title } target="_blank">
            <h3 className="article-item-title">
              { this.props.title }
            </h3>
            <div className="article-item-data">
              <span className="article-item-text">[AD]</span>
            </div>
          </a>
        </div>
      </div>
    )
  }
}

export default AdArticle;
