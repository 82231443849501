import React from 'react';
import {
  Modal
} from 'react-bootstrap';
import Navigation from './navigation';
import Sidemenu from './sidemenu';
import { withRouter } from './with_router';

class BasicLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      toggled: false,
      showInquiry: false
    }

    this.main = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.main.current.scrollTo(0, 0);
    }
  }

  handleToggleSidebar = (value) => {
    this.setState({
      toggled: value
    });
  }

  handleShowInquiry = () => {
    this.setState({
      showInquiry: true
    })
  }

  handleCloseInquiry = () => {
    this.setState({
      showInquiry: false
    })
  }

  render() {
    return (
      <div className="app">
        <Navigation
          handleToggleSidebar={ this.handleToggleSidebar }
        />
        <div className="app-main">
          <Sidemenu
            toggled={ this.state.toggled }
            handleToggleSidebar={ this.handleToggleSidebar }
          />
          <main ref={ this.main }>
            { this.props.children }
            <footer className="mt-4">
              <p className="text-center inquiry-link" onClick={ this.handleShowInquiry }>
                お問い合わせ
              </p>
              <p className="text-center text-muted">
                &copy;{ new Date().getFullYear() } 無料<span className="text-danger">AV</span>すとりーむ
              </p>
            </footer>
          </main>
        </div>
        <Modal show={ this.state.showInquiry } onHide={ this.handleCloseInquiry }>
          <Modal.Header closeButton>
            <Modal.Title>お問い合わせ</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            以下にメールをお送りください。<br/>
            <a href="mailto:info@avstream.link">info@avstream.link</a>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

export default withRouter(BasicLayout);
