import React from 'react';
import { FaHome, FaVideo, FaTag, FaSearch } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import {
  Form,
  FormControl,
  Button,
} from 'react-bootstrap';
import {
  ProSidebar,
  SidebarHeader,
  SidebarContent,
  Menu,
  MenuItem,
  SubMenu
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import ApiClient from '../services/api_client';
import { withRouter } from './with_router';


class Sidemenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchWord: "",
      actressTags: [],
      popularTags: [],
      showInquiry: false
    }
  }

  componentDidMount = async () => {
    this.loadData();
  }

  loadData = async () => {

    this.setState({
      searchWord: "",
      actressTags: [],
      popularTags: []
    });

    const apiPath = `/api/tags/sidemenu`
    const req = await ApiClient.getRequestTo(apiPath);

    fetch(req).then((res) => {
      if (!res.ok) {
        throw Error(`[GET ${ apiPath }] ${ res.status } ${ res.statusText }`);
      }

      return res.json();
    }).then((json) => {
      this.setState({
        actressTags: json.data.actress_tags,
        popularTags: json.data.popular_tags
      });
    }).catch((err) => {
      console.error(err);
    }).finally(() => {
    });
  }

  handleSearchWordChange = (e) => {
    this.setState({
      searchWord: e.target.value
    })
  }

  handleSearchSubmit = (e) => {
    e.preventDefault();
    if (this.state.searchWord) {
      this.props.navigate(`/word/${ this.state.searchWord }`);
    }
  }

  handleMenuItemTap = (e) => {
    this.props.handleToggleSidebar(false);
    this.setState({
      searchWord: ""
    });
  }

  render() {

    let actressTagList = this.state.actressTags.map(tag => {
      return (
        <MenuItem key={ tag.name } prefix="#" onClick={ this.handleMenuItemTap }>
          { tag.name }<Link to={ `/tags/${ tag.name }` } />
        </MenuItem>
      );
    });

    let popularTagList = this.state.popularTags.map(tag => {
      return (
        <MenuItem key={ tag.name } prefix="#" onClick={ this.handleMenuItemTap }>
          { tag.name }<Link to={ `/tags/${ tag.name }` } />
        </MenuItem>
      );
    });

    return (
      <div className="sidemenu-container">
        <ProSidebar
          toggled={ this.props.toggled }
          onToggle={ this.props.handleToggleSidebar }
          breakPoint="md"
        >
          <SidebarHeader>
            <div className="search-box">
              <Form className="d-flex" onSubmit={ this.handleSearchSubmit }>
                <FormControl
                  type="search"
                  placeholder="検索"
                  className="me-2"
                  aria-label="Search"
                  value={ this.state.searchWord }
                  onChange={ this.handleSearchWordChange }
                />
                <Button type="submit" variant="outline-secondary" >
                  <FaSearch />
                </Button>
              </Form>
            </div>
          </SidebarHeader>
          <SidebarContent>
            <Menu iconShape="">
              <MenuItem icon={ <FaHome /> } onClick={ this.handleMenuItemTap }>
                ホーム<Link to="/" />
              </MenuItem>
              <SubMenu title="女優" icon={ <FaVideo />} defaultOpen={ true }>
                { actressTagList }
              </SubMenu>
              <SubMenu title="タグ" icon={ <FaTag />} defaultOpen={ true }>
                { popularTagList }
              </SubMenu>
            </Menu>
          </SidebarContent>
        </ProSidebar>
      </div>
    )
  }
}

export default withRouter(Sidemenu);
