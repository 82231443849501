import {
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";

export const withRouter = (Component) => {
  return props =>
    <Component
      { ...props }
      params={ useParams() }
      location={ useLocation() }
      navigate={ useNavigate() }
    />;
}
