import React from 'react';
import { Admin, Resource } from 'react-admin';
import { fetchUtils } from 'ra-core';
import simpleRestProvider from 'ra-data-simple-rest';
import { VideoList, VideoEdit } from './resources/videos';
import { AdList, AdEdit, AdCreate } from './resources/ads';
import { TagList, TagEdit, TagCreate } from './resources/tags';
import authProvider from './auth_provider';
import '../../styles/Admin.scss';

const AdminRoute = () => {
  const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    // ref: Best practice for auth: https://marmelab.com/react-admin/AuthProviderWriting.html
    options.user = {
      authenticated: true,
      token: localStorage.getItem('password')
    };
    return fetchUtils.fetchJson(url, options);
  };

  const dataProvider = simpleRestProvider(
    process.env.REACT_APP_API_BASE_URL + '/api/admin',
    httpClient,
    'X-Total-Count'
  );

  return (
    <Admin
      basename="/admin"
      dataProvider={ dataProvider }
      authProvider={ authProvider }
    >
      <Resource name="videos" list={ VideoList } edit={ VideoEdit } />
      <Resource name="ads" list={ AdList } edit={ AdEdit } create={ AdCreate } />
      <Resource name="tags" list={ TagList } edit={ TagEdit } create={ TagCreate } />
    </Admin>
  )
}

export default AdminRoute;
