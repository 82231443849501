const getRequestTo = (endpoint, query = {}) => {
  return new Request(toFullURL(endpoint, query), {
    method: 'GET'
  })
}

const toFullURL = (endpoint, query) => {
  let queryStr = Object.keys(query).map(key => `${key}=${query[key]}`).join('&')
  return process.env.REACT_APP_API_BASE_URL + endpoint + ( queryStr && `?${ queryStr }`)
}

const exportedObject = {
  getRequestTo
}

export default exportedObject
